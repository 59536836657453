<script>
  let theme = "light";
  let coinFlip = Math.random();
  // console.log(coinFlip);
  if (coinFlip < 0.5) {
    theme = "dark";
  }

  let darkBkg =
    "radial-gradient(68.26% 147.99% at 31.74% 35.84%, #505050 0%, #3F3F3F 100%)";

  let lightBkg =
    "radial-gradient(50% 108.4% at 50% 50%, rgba(255, 255, 255, 0.2) 31.25%, #FFEDE6 100%)";

  let darkTextColor = "#E5E5E5";
  let lghtTextColor = "#575757";

  let bkGrad = "";
  let textClr = "";
  if (theme == "dark") {
    bkGrad = darkBkg;
    textClr = darkTextColor;
  } else {
    bkGrad = lightBkg;
    textClr = lghtTextColor;
  }

  let submitDisabled = true;
  let inputtedEmail = "";

  //   $: submitDisabled = ValidateEmail(inputtedEmail) ? false : true;
  submitDisabled = false;

  function ValidateEmail(mailStr) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mailStr)) {
      return true;
    }
    return false;
  }

  function submitEmail(event) {
    event.preventDefault();
    console.log(inputtedEmail);
  }
</script>

<style>
  main {
    text-align: center;
    padding: 0;
    margin: 0;
    font-family: "Open Sans", sans-serif;

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background: var(--theme-color);
    color: var(--text-color);
  }

  .page-content-container {
    width: 80%;
    max-width: 1000px;
    height: 100%;
    margin: 0 auto;

    /* background: grey; */
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 1.2rem;
  }

  .header a {
    color: #ed765e;
  }

  .signup-container {
    width: 80%;
    max-width: 300px;
    padding: 100px 0px 0px 0px;
    margin: 0px auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  h1 {
    padding: 0px;
    margin: 0px;
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 52px;
    letter-spacing: 0.2em;
    color: #ed765e;
    /* filter: blur(1px); */
  }

  h2 {
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    letter-spacing: 0.1em;
    padding: 0px;
    margin: 0px;
    margin-top: 70px;
  }

  h3 {
    font-family: "Noto Sans", sans-serif;
    width: 300px;
    font-style: normal;
    font-weight: normal;
    font-size: 1.3rem;
    padding: 0px;
    margin: 0px;
    margin-top: 15px;
  }

  .bigger-font {
    font-size: 1.7rem;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 40px;
  }

  .email {
    width: 300px;
    height: 50px;
    /* background: linear-gradient(130.72deg, rgb(226, 169, 157) 2.7%, rgb(255, 216, 179) 112.9%); */
    background: linear-gradient(
      130.72deg,
      rgba(237, 118, 94, 0.4) 2.7%,
      rgba(254, 168, 88, 0.4) 112.9%
    );
    border-radius: 7px;
    border: none;
    outline: none;
    text-transform: uppercase;
    padding: 0px 20px;
    letter-spacing: 0.1em;
    font-size: 0.8rem;
    color: var(--text-color);
  }

  .email:hover {
    background: linear-gradient(
      130.72deg,
      rgba(237, 118, 94, 0.5) 2.7%,
      rgba(254, 168, 88, 0.5) 112.9%
    );
  }

  .email:focus {
    background: linear-gradient(
      130.72deg,
      rgba(237, 118, 94, 0.5) 2.7%,
      rgba(254, 168, 88, 0.5) 112.9%
    );
  }

  input::placeholder {
    color: var(--text-color);
    opacity: 0.7;
    font-size: 1rem;
  }

  label {
    text-transform: uppercase;
    padding: 5px;
    margin: 0px;
    font-size: 12px;
    letter-spacing: 0.1em;
    text-align: left;
    opacity: 0.7;
  }

  .button {
    width: 300px;
    height: 50px;
    background: linear-gradient(123.5deg, #fea858 -5.35%, #ed765e 63.39%);
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
    /* filter: blur(1px); */
    border-radius: 7px;
    border: none;
    outline: none;
    text-transform: uppercase;
    padding: 0px;
    margin: 0px;
    margin-top: 20px;
    line-height: 35px;
    font-size: 31px;
    letter-spacing: 0.1em;
    opacity: 0.9;
    color: #575757;
  }

  .button:hover {
    opacity: 1;
  }

  .button:active {
    box-shadow: none;
  }

  .button:focus {
    box-shadow: none;
  }

  .chart-container {
    position: relative;
    z-index: 0;
  }

  .bubble1 {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 200px;
    height: 200px;
    border-radius: 100px;
    z-index: 0;
    background: radial-gradient(
      64.4% 64.4% at 54.8% 57%,
      rgba(6, 255, 107, 0.31) 57.81%,
      rgba(255, 255, 255, 0.6) 110%
    );
    filter: blur(1px);
  }

  .bubble2 {
    position: absolute;
    width: 220px;
    height: 220px;
    border-radius: 105px;
    z-index: 1;
    background: rgba(102, 255, 136, 0.24);
    filter: blur(5px);
  }

  @media (max-width: 1000px) {
    .page-content-container {
      width: 100%;
    }

    .header {
      padding: 0px 20px;
    }
  }

  @media (max-height: 800px) {
    .signup-container {
      padding: 40px 0px 0px 0px;
    }
  }
</style>

<svelte:head>
  <link
    href="https://fonts.googleapis.com/css?family=Montserrat|Noto+Sans|Open+Sans&display=swap"
    rel="stylesheet" />
</svelte:head>

<main style="--theme-color: {bkGrad}; --text-color: {textClr}">
  <div class="page-content-container">
    <div class="header">
      <a href="http://app.folio.finance">sign in</a>
    </div>
    <div class="signup-container">
      <h1>Folio</h1>
      <h3>
        <span class="bigger-font">Updates</span>
        coming soon!
      </h3>
      <h3>Sign up to be notified</h3>

      <!-- Begin Mailchimp Signup Form -->
      <div id="mc_embed_signup">
        <form
          action="https://finance.us15.list-manage.com/subscribe/post?u=74043c2a18f81715b48e5e6df&amp;id=096c35d47e"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_blank"
          novalidate>
          <div id="mc_embed_signup_scroll">

            <div class="mc-field-group">
              <label for="mce-EMAIL">Email Address</label>
              <input
                type="email"
                value=""
                placeholder="john.doe@example.com"
                name="EMAIL"
                class="required email"
                id="mce-EMAIL" />
            </div>
            <div id="mce-responses" class="clear">
              <div
                class="response"
                id="mce-error-response"
                style="display:none" />
              <div
                class="response"
                id="mce-success-response"
                style="display:none" />
            </div>
            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px;" aria-hidden="true">
              <input
                type="text"
                name="b_74043c2a18f81715b48e5e6df_096c35d47e"
                tabindex="-1"
                value="" />
            </div>
            <div class="clear">
              <input
                type="submit"
                value="subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                class="button" />
            </div>
          </div>
        </form>
      </div>
      <!--End mc_embed_signup-->

      <h2>See Your Investments In a Better Way</h2>
    </div>

    <!-- <div class="chart-container">
      <div class="bubble1" />
      <div class="bubble2" />
    </div> -->
  </div>
</main>
